body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  min-height: calc(
    var(--vh, 1vh) * 100
  ); /* Use vh as a fallback for browsers that do not support Custom Properties */
  display: flex;
}

.glare-wrapper {
  pointer-events: none;
}

.kimi {
  font-family: "Kimi";
}

.merriweather {
  font-family: "merriweather";
  font-weight: 800;
}

.page {
  width: 100%;
  overflow: auto;
}

.container {
  overflow: auto;
  position: relative;
  min-height: min-content;
}

.padding {
  padding: 20px;
}

.noPadding {
  padding: 0px;
}

.cardHeader {
  padding: 25px;
  position: absolute;
  margin-top: -50px;
  margin-left: -5px;
}

.player-wrapper {
  width: auto;
  height: auto;
  background: black;
}

.card {
  padding: 20px;
  margin-top: 40px;
  position: relative;
}

@font-face {
  font-family: "Kimi";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/Kimi.ttf"); /* IE9 Compat Modes */
}

.MuiBackdrop-root {
  background: rgba(245, 245, 245, 0.8) !important;
  backdrop-filter: blur(20px);
  --webkit-backdrop-filter: blur(20px);
}

.p-right {
  padding-right: 4px;
}

.p-left {
  padding-left: 4px;
}

.node-label {
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
}

.scene-tooltip {
  color: white !important;
  font-size: 18px !important;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.keyboardKey {
  border: solid 1px black;
  padding: 2px 8px;
  border-radius: 6px;
  text-align: center;
}

li:not(:last-child) {
  margin-bottom: 8px;
}

.MuiSnackbar-root .MuiPaper-root {
  box-shadow: none !important;
}

.comingSoon {
  font-size: 12px;
  border: solid 1px black;
  border-radius: 12px;
  margin-left: 6px;
  padding: 0px 4px;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}